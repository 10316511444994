// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$black-txt: #121212;
$body-bg: #f3f4f5;
$border-radius: .4rem;
$success: #7952b3;

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";


//
// Custom styles
//

@import "icon-list";

html,body {
  height: auto;
}

// Style Bootstrap icons
.bi {
  fill: currentColor;
}

// GENERAL
$font-main: "futura-pt",sans-serif;
$font-condensed: "futura-pt-condensed",sans-serif;
$font-bold: "futura-pt-bold",sans-serif;

* {
  font-family: $font-main;
  color: $black-txt;
}

strong {
  font-family: $font-bold;
}

main,
section {
  height: 100%;
}

p.text-large {
  font-size: 40px;
  line-height: 1.25;
  letter-spacing: -1px;
}

p.text-large-2 {
  font-size: 35px;
  line-height: 1.25;
  letter-spacing: -1px;
}

.font-italic {
  strong {
    font-style: normal;
  }
}

div.menu-button {
  height: 70px;
  width: 70px;
  border: 5px solid black;
  font-size: 35px;
  font-weight: 900;
  line-height: 0.8;
  padding-top: 2px;
  text-align: center;
  color: black;
  z-index: 10;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

}

h5.head-title {
  position: relative;
  border-bottom: 2px solid black;
}

// MENU

aside {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.35);
  div.menu-content {
    position: absolute;
    width: 50%;
    height: 100%;
    background: $body-bg;
    right: 0;
    top: 0;
    z-index: 1;
    box-shadow: -12px 0 60px rgba(0,0,0,0.35);
    padding: 30px 30px 30px 39px;
    h5.head-title {
      position: relative;
      top: -3px;
      padding-bottom: 4px;
      width: calc(100% - 100px);
    }
    ul {
      list-style: none;
      li {
        font-size: 50px;
        line-height: 1.25;
        letter-spacing: -1px;
        font-weight: 900;
        > a {
          color: $black-txt !important;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    img {
      width: 40px;
    }
  }
}

// HEADER

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 0;
  background: $body-bg;
  padding: 25px 30px 0 30px;
  div.menu-logo {
    position: relative;
    top: 5px;
    width: 70px;
    height: 70px;
  }
  div.menu-button {
    position: relative;
    top: 5px;
  }
  h5.head-title {
    width: calc(100% - 200px);
    padding-right: 60px;
    z-index: 10;
    height: 30px;
    position: relative;
    top: 2px;
  }
}

// SECTIONS

#first-fold {
  position: relative;
  z-index: 1;
  background: $body-bg;
  div.menu-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }
  h5.head-title {
    padding-bottom: 4px;
    width: calc(100% - 91px);
    margin-top: 19px;
  }
  img.arrow-down {
    position: absolute;
    bottom: calc((100% - 340px)/2 - 80px);
    left: 0;
  }
  h1 {
    font-size: 75px;
    letter-spacing: -1px;
    line-height: 0.9;
    color: black;
  }
}

#investment-thesis {
  padding: 120px 0;
}

#our-investments {
  background: black;
  img {
    width: 80%;
    height: 85%;
    &.veve {
      width: 65%;
    }
    &.ox {
      height: 65%;
    }
  }
}

#value-add {
  ul {
    li {
      p {
        font-size: 24px;
        line-height: 1.25;
      }
    }
  }
}

footer {
  p.footer-tldr {
    font-size: 9px;
    font-family: monospace;
  }
  hr {
    border-top: 4px solid black;
  }
  img {
    width: 80px;
  }
}

@media all and (max-width: 768px) {
  
  section,
  footer {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }

  p.text-large {
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -1px;
  }

  p.text-large-2 {
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -1px;
  }

  // MENU

  aside {
    div.menu-content {
      width: 100vw;
      box-shadow: none;
      padding: 80px 40px 40px 40px;
      h5.head-title {
        font-size: 16px;
        line-height: 1;
        padding-bottom: 0;
        border-bottom: 0;
        width: auto;
        top: -20px;
      }
      hr {
        position: absolute;
        width: calc(100vw - 95px);
        top: 96px;
        left: -25px;
        border-top: 2px solid black;
        z-index: 1;
      }
      ul {
        li {
          font-size: 35px;
          line-height: 1;
        }
      }
      h5 {
        font-size: 16px;
      }
      img {
        width: 30px;
      }
    }
  }

  // HEADER

  header {
    padding: 25px 40px 0 40px;
    z-index: 99999;
    div.menu-button {
      position: relative;
      top: 5px;
    }
    h5.head-title {
      width: auto;
      padding-right: 0;
      height: auto;
      top: 2px;
      font-size: 16px;
      line-height: 1;
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 17px;
    }
    hr {
      position: absolute;
      width: calc(100vw - 95px);
      top: 95px;
      left: -25px;
      border-top: 2px solid black;
      z-index: 1;
    }
  }

  // SECTIONS

  #first-fold {
    div.menu-button {
      right: 15px;
    }
    img.arrow-down {
      position: absolute;
      bottom: -10px;
      left: 0;
    }
    h5.head-title {
      font-size: 16px;
      line-height: 1;
      padding-bottom: 0;
      border-bottom: 0;
    }
    h1 {
      font-size: 50px;
      letter-spacing: -1px;
      line-height: 1;
      color: black;
      padding: 100px 0 90px 0;
    }
    hr {
      position: absolute;
      width: calc(100vw - 120px);
      left: -25px;
      border-top: 2px solid black;
      z-index: 1;
    }
  }

  #investment-thesis {
    padding: 120px 0 60px 0;
  }

  footer {
    hr {
      border-top: 2px solid black;
    }
    div.row {
      border-top: 0;
    }
    img {
      width: 50px;
    }
    h2 {
      font-size: 30px;
    }
  }

}

.owl-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    height: 100%;
    width: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;

    &.owl-prev {
      position: relative;
      background: transparent !important;
      > span {
        display: block;
        font-size: 0;
        width: 100px;
        height: 30px;
        background: transparent !important;
        &:before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url('../img/arrow-white.svg') center center no-repeat transparent;
        }
      }
    }

    &.owl-next {
      position: relative;
      background: transparent !important;
      > span {
        display: block;
        font-size: 0;
        width: 100px;
        height: 30px;
        background: transparent !important;
        &:before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url('../img/arrow-white.svg') center center no-repeat transparent;
          transform: rotate(180deg);
        }
      }
    }
  }
}

@keyframes expandWidth {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.animate__expandWidth {
  animation-name: expandWidth;
}